//arrow keys
const left_arrow = 37;
const up_arrow = 38;
const right_arrow = 39;
const down_arrow = 40;

//state
const next = 'NEXT';
const prev = 'PREV';

export { left_arrow, up_arrow, right_arrow, down_arrow, next, prev };